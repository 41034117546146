import { SearchQuery } from "../../query/search/Search";
import type { SortOrder } from "~/interfaces/list";

export default function getSearch(
    langcode: String,
    query: String,
    filters: Array<Object>,
    limit: Number,
    offset: Number,
    sort?: SortOrder
  ) {
    return useAsyncQuery(SearchQuery, {
      langcode,
      query,
      filters,
      limit,
      offset,
      sort
    });
  }