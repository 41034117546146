import { gql } from "graphql-tag";

export const ListNewsSearchQuery = gql`
  query ListNewsSearchQuery(
    $langcode: String!
    $query: String
    $filters: [InputOpenSearchConditionsGroup]
    $limit: Int
    $offset: Int
    $sort: InputSort
  ) {
    opensearchSearch(
      index: "curie"
      limit: $limit
      langcode: $langcode
      filters: $filters
      facets: [{ field: "field_domain_news", limit: 1000 }]
      query: $query
      offset: $offset
      sort: $sort
    ) {
      count
      documents {
        entity {
          id
          langcode
          entityBundle
          ... on NodeNews {
            id
            fieldTitle
            fieldSubtitle
            fieldDate {
              value
            }
            fieldImage {
              id: targetId
              entity {
                id
                uriRawField {
                  list {
                    url
                  }
                }
              }
            }
            pathRawField {
              list {
                alias
              }
            }
          }
        }
      }
      facets {
        field
        values {
          field
          value
          count
          label: entity {
            ... on TaxonomyTermDomainNews {
              value: name
            }
          }
        }
      }
    }
  }
`;
