import { defineStore } from "pinia";

import getNewsListSearch from "~/composables/service/graphql/client/news-list-search";
import getJobOfferListSearch from "~/composables/service/graphql/client/job-offer-list-search";
import getTeamListSearch from "~/composables/service/graphql/client/team-list-search";
import getPersonListSearch from "~/composables/service/graphql/client/person-list-search";
import getUnitListSearch from "~/composables/service/graphql/client/unit-list-search";
import getPublicationListSearch from "~/composables/service/graphql/client/publication-list-search";
import getClinicalTrialListSearch from "~/composables/service/graphql/client/clinical-trial-list-search";
import getSeminarListSearch from "~/composables/service/graphql/client/seminar-list-search";
import getSearch from "~/composables/service/graphql/client/search";

import type {
  ApiCondition,
  Facet,
  OpenSearchResult,
  OpenSearchFacet,
  SearchFunctionKey,
  OpenSearchFacetValue,
  SortOrder,
} from "~/interfaces/list";

export const useList = defineStore("list", {
  state: () => ({
    searchData: <OpenSearchResult>{},
    baseConditions: <ApiCondition[]>[],
    facetConditions: <ApiCondition[]>[],
    facets: <Facet[]>[],
    sortOrder: <SortOrder> {},
    selectedDateRange: {
      start: new Date(),
      end: new Date(),
    },
    langcode: useRuntimeConfig().public.langcode,
    paginationLimit: parseInt(useRuntimeConfig().public.paginationLimit, 10) || 10,
    currentPage: 1,
    totalPages: 1,
    query: "",
    isFirstFetch: true,
    retryCount: 1,
    baseParameters: ["q", "page"],
    page: "",
  }),
  getters: {
    conditions: (state) => [...state.baseConditions, ...state.facetConditions],
    offset: (state) => (state.currentPage - 1) * state.paginationLimit,
  },
  actions: {
    $reset(){
      this.selectedDateRange = {
        start: new Date(),
        end: new Date(),
      };
      this.currentPage = 1;
      this.totalPages = 1;
      this.query = "";
    },
    setFacets(facets: Facet[]) {
      this.facets = facets;
    },
    setFacetConditions(conditions: ApiCondition[]) {
      this.facetConditions = conditions;
    },
    setBaseConditions(conditions: ApiCondition[]) {
      this.baseConditions = conditions;
    },
    setCurrentPage(page: number) {
      this.currentPage = page;
    },
    setSortOrder(sortOrder: SortOrder) {
      this.sortOrder = sortOrder;
    },
    setQuery(query: string) {
      this.query = query;
    },
    getSearchFunction(page: SearchFunctionKey): Function | undefined {
      const searchFunctions: Record<SearchFunctionKey, Function> = {
        newsList: getNewsListSearch,
        jobOfferList: getJobOfferListSearch,
        teamList: getTeamListSearch,
        personList: getPersonListSearch,
        unitList: getUnitListSearch,
        publicationList: getPublicationListSearch,
        clinicalTrialList: getClinicalTrialListSearch,
        seminarList: getSeminarListSearch,
        search: getSearch,
      };
      return searchFunctions[page];
    },

    async fetchSearchData(page: SearchFunctionKey) {
      const searchFunction = this.getSearchFunction(page);
      if (!searchFunction) {
        console.error("Invalid search function:", page);
        return;
      }
      if(this.query.length>0){
        this.setSortOrder({field: "search_api_relevance", order:"DESC"});
      }
      try {
        const { data } = await searchFunction(
          this.langcode.toLowerCase(),
          this.query,
          this.conditions,
          this.paginationLimit,
          this.offset,
          this.sortOrder
        );

        if (!data?.value?.opensearchSearch) {
          throw new Error("No valid data returned from search function");
        }

       

        const openSearchData = data.value.opensearchSearch;
        this.searchData = openSearchData;
        this.totalPages = Math.ceil(openSearchData.count / this.paginationLimit);
        if(this.currentPage > this.totalPages && this.totalPages>0){
          await navigateTo({ path: '/404' })
        }
        const facetsWithFilters = this.facets.map((facet) => ({
          ...facet,
          ...(facet.type === "Checkbox" || facet.type === "Radio"
            ? {
                filters:
                  openSearchData.facets.find(
                    (openSearchFacet: OpenSearchFacet) =>
                      openSearchFacet.field === facet.field && openSearchFacet.values?.length > 0
                  )?.values || [],
              }
            : {}),
        }));
        this.setFacets(facetsWithFilters);

        if (this.isFirstFetch) {
          this.isFirstFetch = false;
        }
      } catch (error) {
        console.error("Fetch error:", error);
        if (this.retryCount > 0) {
          console.log("Retrying fetch...");
          this.retryCount -= 1;
          await this.fetchSearchData(page);
        }
      }
    },
  },
});