import { gql } from "graphql-tag";

export const ListPersonSearchQuery = gql`
  query ListPersonSearchQuery(
    $langcode: String!
    $query: String
    $filters: [InputOpenSearchConditionsGroup]
    $limit: Int
    $offset: Int
    $sort: InputSort
  ) {
    opensearchSearch(
      index: "curie"
      limit: $limit
      langcode: $langcode
      filters: $filters
      facets: [
        { field: "field_person_site", limit: 1000 }
        { field: "computed_person_function_for_facet", limit: 1000 }
        { field: "field_person_specialized_medicin", limit: 1000 }
        { field: "field_person_speciality", limit: 1000 }
        { field: "field_person_cancer_type", limit: 1000 }
        { field: "field_person_department", limit: 1000 }
        { field: "field_person_location", limit: 1000 }
      ]
      query: $query
      offset: $offset,
      sort: $sort
    ) {
      count
      documents {
        entity {
          id
          langcode
          entityBundle
          ... on NodePerson {
            fieldFirstName
            fieldLastName
            fieldPersonTitle {
              name
            }
            fieldPersonSpeciality {
              name
            }
            fieldPersonSpecializedMedicin {
              name
            }
            fieldPersonLocation {
              name
            }
            fieldImage {
              id: targetId
              alt
              entity {
                id
                uriRawField {
                  list {
                    url
                  }
                }
              }
            }
            pathRawField {
              list {
                alias
              }
            }
          }
        }
      }
      facets {
        field
        values {
          field
          value
          count
          label: entity {
            ... on TaxonomyTermPersonSite {
              value: name
            }
            ... on TaxonomyTermPersonFunction {
              value: name
            }
            ... on TaxonomyTermPersonSpecializedMedicin {
              value: name
            }
            ... on TaxonomyTermPersonSpeciality {
              value: name
            }
            ... on TaxonomyTermPersonCancerType {
              value: name
            }
            ... on TaxonomyTermPersonDepartement {
              value: name
            }
            ... on TaxonomyTermPersonLocation {
              value: name
            }
          }
        }
      }
    }
  }
`;
